const variantName = 'aboutAdvancedV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    aboutContainers: {},

    introBlock: {},

    sectionsContainer: {
      // display: 'flex',
      // flexWrap: 'wrap',
      // width: '100%',
      // justifyContent: 'flex-start',
      '> div:nth-of-type(even)': {
        '.content': {
          order: ['', '', '2']
        }
      }
    },
    sectionContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: ['column', '', 'row']
    },

    menuCellsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: '3rem'
    },
    menuCell: {
      width: ['calc(50% - 1rem)', '', 'calc(33.3% - 1rem)', ''],
      position: 'relative',
      margin: '0.5rem',
      overflow: 'hidden',
      cursor: 'pointer',
      ':hover': {
        img: {
          transform: 'scale(1.2)'
        },
        h2: {
          color: 'light'
        }
      }
    },

    menuCellImage: {
      transition: 'all ease-in-out 0.8s',
      height: ['250px', '300px'],
      objectFit: 'cover',
      width: '100%'
    },
    menuCellTitle: {
      transition: 'all ease-in-out 0.8s',
      position: 'absolute',
      bottom: '1rem',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      width: '90%',
      textAlign: 'center',
      backgroundColor: 'rgb(12 12 12 / 82%)',
      color: 'primary',
      height: ['30%', '', '20%'],
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'primary',
      fontWeight: '400',
      fontSize: ['1rem', '1.2rem'],
      justifyContent: 'center',
      transition: 'all ease-in-out 0.5s'
    },

    //  Side Bar

    menuCellsSideBar: {
      position: 'fixed',
      left: '-300px',
      top: '30%',
      backgroundColor: 'primary',
      color: 'white',
      width: '300px',
      padding: '1rem',
      zIndex: '1000'
    },

    sideBarList: {},

    menuCellsSideBarActive: {
      variant: `${variantName}.menuCellsSideBar`,
      left: '0rem'
    },

    menuCellSideBar: {},

    menuCellSideBarTitle: {
      fontSize: '1rem',
      fontWeight: '300',
      margin: '0.5rem',
      borderBottom: 'solid 1px',
      padding: '0.5rem',
      cursor: 'pointer',
      ':hover': {
        color: 'lightgrey'
      }
    },

    menuCellSideBarLabel: {
      position: 'absolute',
      writingMode: 'tb',
      right: '0rem',
      top: '0rem',
      padding: '2rem 0.5rem',
      transform: 'translate(100%, 0px)',
      backgroundColor: 'primary'
    },

    // ?============================
    // ?======  Image Content =======
    // ?============================

    introImage: {
      width: '100%',
      objectFit: 'cover',
      height: '60vh'
    },

    imageContainer: {
      width: ['100%', '', '49%'],
      padding: '1rem',
      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '100%'
      },
      '.slick-slide img': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)'
      },
      '.slick-arrow': {
        width: '30px',
        zIndex: '100',
        height: '30px',
        backgroundColor: 'black',
        path: {
          fill: 'primary'
        }
      },
      '.slick-prev': {
        left: '0rem'
      },
      '.slick-next': {
        right: '0rem'
      }
    },

    sliderImageFill: { paddingBottom: '100%' },
    sliderImage: {},

    // ?============================
    // ?======  Text Content =======
    // ?============================

    introTextContainer: {
      padding: '3rem 1rem',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: '1000px',
      margin: '0rem auto'
    },

    content: {
      display: 'flex',
      padding: ['2rem 1rem', '', '2rem', '3rem 5rem', '3rem 7rem'],
      flexDirection: ['column', '', 'column'],
      width: ['100%', '', '50%'],
      justifyContent: ['', '', 'center']
    },

    title: {
      marginBottom: 3,
      fontSize: 8,
      fontWeight: '300'
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal'
    },
    body: {},

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      display: 'flex',
      marginTop: '1rem',
      textAlign: 'center',
      padding: '1rem 3rem',
      backgroundColor: 'secondary',
      width: 'fit-content',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      // ?== Psuedo Elements ==
      '&:hover': {
        backgroundColor: 'primary'
      }
    }
  }
}
