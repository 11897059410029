export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},
  locationsPage: {
    '.locationsContainer': {
      justifyContent: 'space-between',
      padding: '0.5rem',
      '.locations': {
        a: {
          textTransform: 'uppercase'
        }
      }
    },
    '.location': {
      position: 'relative',
      width: ['', '', 'calc(33.3% - 1rem)', 'calc(33.3% - 1rem)'],
      margin: ['0.25rem', '0.5rem'],
      alignItems: ['flex-end', 'center']
    },
    '.logo': {
      position: 'absolute',
      top: '0rem',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      height: 'auto',
      img: {
        // maxHeight: '110px',
        backgroundColor: 'white',
        padding: ['0.75rem', '', '1rem'],
        maxWidth: ['130px', '', '200px'],
        width: '100%'
      },
      zIndex: '9999'
    },
    '.content': {
      width: '100%'
    },

    '.CTAS': {
      width: '100%'
    },
    '.CTAButton': {
      borderRadius: '3px',
      textTransform: 'uppercase',
      fontSize: ['0.7rem', '0.8rem'],
      padding: ['0.5rem 0.5rem', '0.7rem 1rem'],
      margin: '0.25rem',
      width: 'fit-content',
      ':hover': {
        backgroundColor: 'secondary'
      }
    },

    '.locationTitle': {
      margin: ['0.5rem 0.5rem 1rem', '1rem 1rem 1rem'],
      fontSize: ['1.7rem', '2rem', '2rem', '2.25rem', '2.5rem'],
      textShadow: '1px 1px 14px black'
    },
    '.enterSiteButton': {
      backgroundColor: 'secondary',
      cursor: 'pointer',
      border: 'none',
      borderRadius: '3px',
      padding: ['0.5rem 0.5rem', '0.7rem 1rem'],
      textTransform: 'uppercase',
      fontSize: ['0.7rem', '0.8rem'],
      margin: '0.25rem',
      ':hover': {
        backgroundColor: 'black'
      }
    }
  },

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {}, // shadowed

  footer: {}, // shadowed

  ctaWidget: {
    display: 'none !important',
    zIndex: '999',
    color: 'light',
    '#online-order-cta svg': {
      display: ['none', '', 'block']
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {},
  subtitle: {},
  text: {},

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // height: '75vh',
    '.title': {
      borderBottom: '2px solid',
      borderLeft: 'none',
      paddingLeft: '0rem',
      textTransform: 'uppercase',
      letterSpacing: '-3px'
    },
    '.section': {
      margin: '1rem',
      display: 'none'
    }
  },

  homepageSlider: {
    // slick styles
    padding: '0rem',
    '.slick-initialized .slick-slide > div': {
      overflow: 'hidden',
      position: 'relative',
      height: '60vh'
    },
    '.slick-slide .imageFill': {
      paddingBottom: '60vh'
    },
    '.slick-dots': {
      bottom: '10px'
    }
  },

  homepageReservation: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['1rem 1rem', '0rem 1rem', '0rem 1rem', ' 0rem 1rem'],
    flexDirection: 'column',
    '#scriptWidget': {
      width: '100%',
      '> div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      fontSize: ['2rem', '', '2.5rem'],
      fontWeight: '400'
    },

    '.ot-dtp-picker.wide .ot-dtp-picker-form': {
      height: '197px',
      width: '203px'
    },
    '.ot-dtp-picker.wide': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '.ot-dtp-picker.wide .ot-title': {
      fontSize: ['20px', '', '30px']
    }
  },

  homepageContentBoxes: {
    padding: '0rem',
    maxHeight: '70vh',
    '.contentBoxes': {
      maxHeight: '70vh',
      padding: '0rem',
      display: 'grid',
      gridTemplateColumns: '3fr 1fr 1fr',
      gridTemplateRows: '2fr 2fr',
      gap: '0px 0px',
      gridTemplateAreas: '". . ."". . ."',
      width: '100%',
      a: {
        padding: '0rem',
        height: '100%',
        width: '100%',
        maxHeight: '100vh',
        img: {
          height: '100%',
          width: '100%',
          objectFit: 'cover'
        }
      },
      '> a:nth-of-type(1)': {
        gridColumnStart: '1',
        gridColumnEnd: '2',
        gridRowStart: '1',
        gridRowEnd: '3'
      },
      '> a:nth-of-type(2)': {
        maxHeight: '50vh',
        gridColumnStart: '2',
        gridColumnEnd: '5',
        gridRowStart: '1',
        gridRowEnd: '2'
      },
      '> a:nth-of-type(3)': {
        maxHeight: '50vh',
        gridColumnStart: '2',
        gridColumnEnd: '5',
        gridRowStart: '2',
        gridRowEnd: '3'
      }
    }
  },

  homepageContentBoxes1: {
    padding: '0rem',
    maxWidth: 'unset',
    '.box': {
      height: '400px',
      padding: '0rem',
      width: ['100%', 'calc(50% - 1rem)', '33.33%'],
      margin: ['1rem 0rem', '0.5rem', '0rem'],
      position: 'relative',
      transition: 'all ease-in-out 0.8s',
      overflow: 'hidden',
      ':hover': {
        '.title': {
          // left: '1rem',
          left: '50%'
          // padding: '2rem',
        },
        img: {
          transform: 'scale(1.1)'
        }
      },
      img: {
        height: '100%',
        objectFit: 'cover',
        filter: 'brightness(0.8)',
        margin: '0rem',
        transition: 'all ease-in-out 0.7s'
      }
    },

    '.title': {
      position: 'absolute',
      top: '50%',
      left: ['50%', '', '', '-50%'],
      transform: 'translate(-50%, -50%)',
      padding: '2rem 2rem',
      color: '#8B734D',
      backgroundColor: 'rgb(12 12 12 / 82%)',
      width: '80%',
      margin: '0px auto',
      textTransform: 'uppercase',
      fontWeight: '300',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      textAlign: 'center',
      borderColor: 'primary'
    }
  },

  homepagePrivateDiningSection: {
    padding: ['3rem 1rem', '5rem 1rem', ' 7rem 1rem', ' 10rem 1rem'],
    '.title': {
      fontWeight: '300',
      fontSize: ['2rem', '2.25rem', '2.75rem', '3rem'],
      textTransform: 'uppercase'
    },
    '.subtitle': {
      color: 'primary',
      // borderBottom: '1px solid lightgrey',
      marginBottom: '1rem'
    },
    '.text': {
      opacity: '0.8'
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      // borderBottom: 'solid 1px',
      order: '4'
    }
  },

  homepageContentBoxes2: {
    overflow: 'hidden',
    padding: '0rem',
    width: '100%',
    '.contentBoxes': {
      width: '100vw'
    },
    '.box': {
      width: '25vw',
      height: '25vw',
      margin: '0rem',
      padding: '0rem',
      img: {
        height: '100%',
        objectFit: 'cover'
      }
    }
  },

  homepageGiftcards: {
    backgroundAttachment: 'fixed',
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://res.cloudinary.com/gonation/image/upload/c_scale,w_2500/sites/siena/giftcard-bg.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['5rem 1rem', '', '10rem 1rem'],
    justifyContent: 'center',
    '.lazyload-wrapper': {
      maxWidth: '600px',

      '.image': {
        objectFit: 'contain'
      }
    },
    '.content': {
      padding: ['1rem', '2rem', '3rem', '4rem'],
      maxWidth: '600px',
      order: ['2', '', 'unset']
    },
    '.title': {
      fontSize: ['2rem', '', '2.5rem', '5rem'],
      fontWeight: '300',
      textTransform: 'uppercase'
    },
    '.text': {
      color: 'white',
      fontSize: '1.3rem',
      margin: '2rem 0rem',
      '> *': {
        color: 'white'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      padding: '1.5rem 3rem'
    }
  },

  homepageQuote: {
    variant: 'customVariants.homepagePrivateDiningSection',
    '.title': {
      lineHeight: '1.5',
      fontSize: ['1.5rem', '', '2rem', '2.75rem'],
      fontWeight: '300',
      textTransform: 'uppercase',
      order: '2'
    },
    '.subtitle': {
      order: '1',
      color: 'primary'
    },
    '.section': {
      maxWidth: '1000px'
    },
    a: {
      order: '4'
    },

    '.ctaButton': {
      variant: 'buttons.secondary'
      // borderBottom: 'solid 1px',
    }
  },

  homepageHours: {
    padding: ['2rem 1rem', '4rem 1rem'],
    '.title': {
      // variant: 'customVariants.title',
      fontWeight: '300',
      fontSize: ['2rem', '2.25rem', '2.75rem', '3rem'],
      textTransform: 'uppercase',
      marginBottom: '4rem'
    },
    '.dayofWeekText': {
      color: 'primary',
      marginBottom: '1rem'
    },
    '.closed': {
      textAlign: ['right', '', '', 'center']
    },
    '.dayContainer': {
      flexWrap: ['', '', '', 'nowrap'],
      '.dayContainer': {
        flexDirection: ['column', '', '', 'row']
      }
    }
  },

  instagramLink: {
    '.ctaButton': {
      backgroundColor: 'transparent',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
      margin: '0rem',
      fontWeight: '300',
      textTransform: 'lowercase'
    },
    backgroundColor: '#f2f5f0'
  },

  instagramWidget: {
    backgroundColor: '#f2f5f0',
    '.logoAndTextContainer': {
      display: 'none'
    }
  },

  homepageHeroShout: {},

  homepageShout: {},

  homepageAbout: {},

  homepageMenu: {},

  homepageGallery: {},

  homepageContactUs: {},

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.text': {
      textAlign: 'left'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      borderTop: '2px solid',
      borderBottom: '2px solid'
    },

    '.albumContainer': {
      '.lazyload-wrapper ': {
        width: '100%'
      }
    },

    '.albumTitle': {
      backgroundColor: 'transparent',
      color: 'black',
      border: 'none',
      margin: '1.5rem',
      fontWeight: '300',
      textTransform: 'uppercase'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.container': {
      padding: '0.5rem'
    },
    '.textContent': {
      display: 'none'
    },
    '.heading': {
      display: 'none'
    },
    '.eventItemContainer': {
      width: ['calc(50% - 0.5rem)', '', 'calc(50% - 2rem)'],
      margin: ['0.25rem', '', '1rem']
    },
    '.eventItemImage': {
      maxHeight: '400px'
    },
    '.eventItemTitle': {
      fontSize: ['1.25rem', '1.5rem'],
      fontWeight: '300',
      textTransform: 'uppercase'
    },

    '.eventItemBtn': {
      variant: 'buttons.secondary',
      borderRadius: '0px',
      textAlign: 'center',
      padding: ['0.75rem', '', '', '1rem']
    },

    '.modal': {
      backgroundColor: 'rgba(215,211,204)',
      color: '#6c6c6c',
      border: 'none',
      padding: '0rem',
      maxWidth: '1000px',
      width: ['90%', '', '', '100%'],
      maxHeight: '95vh',
      '.eventItemDateContainer': {
        marginRight: '0rem',
        textAlign: 'right'
      }
    },

    '.modalImage': {
      maxHeight: ['', '', '', '50vh']
    },

    '.modalContentContainer, .modalEventName, .eventMonth, .modalEventDescription': {
      color: '#6c6c6c'
    },
    '.modalContentContainer': {
      display: 'flex',
      flexWrap: 'wrap',
      padding: ['1rem', '', '2rem', '3rem'],
      maxHeight: ['', '', '', '45vh']
    },

    '.modalEventDate': {
      margin: '0 0 0 auto',
      width: '50px',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      fontSize: ['1.25rem', '', '1.5rem'],
      '.eventMonth, .eventDate': {
        fontSize: ['1.25rem', '', '1.5rem']
      }
    },

    '.modalEventTime': {
      width: '100%'
    },

    '.modalEventName': {
      fontSize: ['1.25rem', '', '1.5rem'],
      fontWeight: '400',
      textTransform: 'uppercase',
      width: 'calc(100% - 50px)',
      svg: {
        marginRight: '0.75rem'
      }
    },

    '.modalEventDescription': {
      maxHeight: ['134px', '', 'unset'],
      overflow: ['scroll', '', '', 'unset'],
      '::-webkit-scrollbar': {
        display: 'none'
      },
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none'
    },

    '.eventCTABtns': {
      // padding: '1rem',

      margin: ['0rem auto', '0rem 0rem 0rem auto'],
      a: {
        variant: 'buttons.secondary',
        backgroundColor: '#353535',
        borderColor: '#353535',
        textTransform: 'uppercase',
        padding: ['0.5rem', '0.5rem 1rem', '1rem 2rem'],
        margin: '0.5rem'
      }
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    backgroundColor: '#fffdf4',
    padding: '10vh 2rem',
    order: '4',
    border: ['solid 10px black', 'solid 10px black', 'solid 15px black'],
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'primary',
      marginBottom: '2rem',
      variant: 'customVariants.title',
      color: 'white'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem',
      color: 'white'
    },
    form: {
      input: {
        border: 'none',
        padding: '1.5rem 1rem',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        '::placeholder': {
          color: 'white'
        }
      },

      textarea: {
        padding: '1.5rem 1rem',
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        '::placeholder': {
          color: 'white'
        }
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    // '.hoursContainer': {
    //   '::before': {
    //     content: "'Coming Soon'"
    //   },
    //   '.dayContainer': {
    //     display: 'none'
    //   }
    // }
  },

  // ? ==============
  // ? === Press ===
  // ? ==============

  pressMenu: {
    backgroundColor: 'white'
  }
}
