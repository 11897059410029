export default {
  pageHeroV1: {
    container: {
      height: '75vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['2.5rem', '', '3.5rem'],
        marginBottom: 1
        // display: 'none',
      },
      h2: {
        marginBottom: 4
      },
      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
